<template>
  <div>
    <eden-page-header title="Feedback" :subtitle="pageTitle" />
    <eden-overview-card :title="title" :loading="fetching">
      <template slot="actions">
        <eden-table-actions
          :filter-items.sync="filterItems"
          :show-search="false"
          @filter="
            getFilteredFeedbacks({
              start_date: null,
              end_date: null,
              service: '',
            })
          "
        >
          <el-dropdown
            class="more mx-1 hidden-md-and-down"
            trigger="click"
            @command="setType"
          >
            <el-button type="primary" plain
              >{{ types[type] }} <i class="el-icon-arrow-down"></i
            ></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(value, key, i) in types"
                :key="i"
                :command="key"
                :class="{ selected: key === type }"
                >{{ value }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            v-if="allowExport"
            type="primary"
            @click="showFeedbackExport = true"
            >Export</el-button
          >
        </eden-table-actions>
      </template>
      <template slot="content">
        <div v-if="showFilters" class="filter-items">
          <div class="filter-item">
            <template>
              <span class="label">Clear filters</span>
              <span class="close" @click="setCurrentPageData"
                ><i class="el-icon-close"></i
              ></span>
            </template>
          </div>
        </div>
        <eden-loader
          v-if="fetching || searching || filtering"
          :cols="7"
          type="four-columns-table"
        />
        <div v-else>
          <el-table :data="pageData.data">
            <el-table-column width="200">
              <template slot="header">
                <span class="eden-table-header"> Customer </span>
              </template>
              <template slot-scope="scope">
                <p>{{ formatName(scope.row[0].customer_name) }}</p>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <span class="eden-table-header"> Services </span>
              </template>
              <template slot-scope="scope">
                <el-table :data="pageData.data[scope.$index]">
                  <el-table-column width="160">
                    <template slot="header">
                      <span class="eden-table-header">
                        Date
                        <i class="el-icon-bottom"></i>
                      </span>
                    </template>
                    <template slot-scope="scope">
                      <p>
                        {{
                          scope.row.delivery_date
                            ? formatDate(scope.row.delivery_date, "mo d, y")
                            : "-"
                        }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="service" width="100">
                    <template slot="header">
                      <span class="eden-table-header">
                        Services
                        <i class="el-icon-bottom"></i>
                      </span>
                    </template>
                    <template slot-scope="scope">
                      <el-tag
                        v-for="(service, index) in scope.row.service.split(',')"
                        :key="index"
                        :style="{
                          backgroundColor: `${setServiceStyle(service)}`,
                        }"
                        >{{ formatText(service) }}</el-tag
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="commodity" width="300">
                    <template slot="header">
                      <span class="eden-table-header">
                        Item
                        <i class="el-icon-bottom"></i>
                      </span>
                    </template>
                    <template slot-scope="scope">
                      <p>
                        {{ formatToTitleCase(scope.row.commodity) }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column width="200">
                    <template slot="header">
                      <span class="eden-table-header">
                        Item Feedback
                        <i class="el-icon-bottom"></i>
                      </span>
                    </template>
                    <template slot-scope="scope">
                      <p v-if="scope.row.commodity_reasons">
                        {{ scope.row.commodity_reasons.join(", ") }}
                      </p>
                      <p v-else>-</p>
                    </template>
                  </el-table-column>
                  <el-table-column width="300">
                    <template slot="header">
                      <span class="eden-table-header">
                        Comment
                        <i class="el-icon-bottom"></i>
                      </span>
                    </template>
                    <template slot-scope="scope">
                      <p>
                        {{
                          formatText(scope.row.commodity_extra_feedback) || "-"
                        }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="general_experience_reasons"
                    width="290"
                  >
                    <template slot="header">
                      <span class="eden-table-header">
                        Reasons
                        <i class="el-icon-bottom"></i>
                      </span>
                    </template>
                    <template slot-scope="scope">
                      <div class="is-flex flex-wrap">
                        <div v-if="scope.row.general_experience_reasons.length">
                          <p>
                            {{
                              scope.row.general_experience_reasons.join(", ")
                            }}
                          </p>
                        </div>
                        <p v-else class="ml-2">-</p>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="general_experience_feedback"
                    width="300"
                  >
                    <template slot="header">
                      <span class="eden-table-header">
                        Extra Feedback
                        <i class="el-icon-bottom"></i>
                      </span>
                    </template>
                    <template slot-scope="scope">
                      <p>
                        {{
                          formatText(scope.row.general_experience_feedback) ||
                          "-"
                        }}
                      </p>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
          </el-table>
          <eden-pagination
            v-if="pageData.data.length && !hidePagination"
            :from="pageData.from"
            :to="pageData.to"
            :total="pageData.total"
            :current-page.sync="page"
          />
        </div>
      </template>
    </eden-overview-card>
    <feedback-export
      :show.sync="showFeedbackExport"
      :export-data="pageData.data"
    />
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
import feedback from "@/requests/feedback/feedback";
import FeedbackExport from "@/components/Feedback/Feedback/FeedbackExport";

export default {
  name: "Feedback",
  components: { FeedbackExport },
  data() {
    return {
      fetching: false,
      searching: false,
      filtering: false,
      page: 1,
      pageData: {
        from: null,
        to: null,
        total: null,
        data: [],
      },
      hidePagination: false,
      filterItems: {
        service: {
          label: "Service",
          type: "select-with-objects",
          options: [],
          value: "",
        },
        generalFeedbackReasons: {
          label: "General Feedback Reasons",
          type: "multi-select",
          options: [],
          value: [],
        },
        gardener: {
          label: "Gardener",
          type: "select-with-objects",
          options: [],
          value: "all",
        },
      },
      showFilters: false,
      periods: {
        last90: "Last 90 days",
        last60: "Last 60 days",
        last30: "Last 30 days",
        lastmonth: "Last month",
        lastweek: "Last week",
        thisweek: "This week",
        thismonth: "This month",
      },
      types: {
        happy: "Happy Customers",
        unhappy: "Unhappy Customers",
      },
      reasons: {
        cleaning: [],
        laundry: [],
        meal: [],
      },
      showFeedbackExport: false,
    };
  },
  computed: {
    period() {
      return this.$route.query.period;
    },
    start() {
      return this.$route.query.start_date;
    },
    end() {
      return this.$route.query.end_date;
    },
    type() {
      return this.$route.params.type;
    },
    pageTitle() {
      let period;

      if (this.period) {
        period = this.periods[this.period];
      } else {
        period = `${this.formatDate(this.start, "mo d, y")} - ${this.formatDate(
          this.end,
          "mo d, y",
        )}`;
      }

      return `${this.formatText(this.type)} (${period})`;
    },
    title() {
      return `${this.pageData.total || 0} ${this.formatText(
        this.type,
      )} Customers`;
    },
    feedback() {
      return this.$store.getters.feedback[this.type][this.page];
    },
    allowExport() {
      return !!this.start;
    },
  },
  watch: {
    page() {
      if (!this.feedback) {
        this.getFeedback();
      } else {
        this.setCurrentPageData();
      }
    },
    filterItems: {
      deep: true,
      handler() {
        const service = this.filterItems.service.value;
        if (service) {
          this.filterItems.generalFeedbackReasons.options =
            this.reasons[service];
        }
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    if (to.query) {
      next();
    } else {
      next({ name: "feedback.index" });
    }
  },
  created() {
    const { period, start_date, end_date, service } = this.$route.query;

    if (start_date && end_date && service) {
      this.getFilteredFeedbacks({ start_date, end_date, service });
    } else if (start_date && end_date) {
      this.getFilteredFeedbacks({ start_date, end_date });
    } else if (period && service) {
      this.getFilteredFeedbacks({ service });
    } else {
      this.getFeedback();
    }
  },
  methods: {
    setType(type) {
      this.$router.push({
        name: "feedback.type",
        params: { type },
        query: this.$route.query,
      });
      this.getFeedback();
    },
    setCurrentPageData() {
      const data = this.$store.getters.feedback[this.type][this.page];

      if (data) {
        this.pageData = {
          ...data,
        };
        this.hidePagination = false;
        this.showFilters = false;
      } else {
        this.getFeedback();
      }
    },
    getFeedback() {
      this.fetching = true;
      this.$store
        .dispatch(actions.GET_FEEDBACK, {
          period: this.period ? this.period : "lastweek",
          type: this.type,
          page: this.page,
        })
        .then(() => {
          this.getReasons();
          this.setCurrentPageData();
          this.fetching = false;
        });
    },
    getFilteredFeedbacks({ start_date, end_date, service }) {
      let payload = {
        service: service ? service : this.filterItems.service.value || "all",
        reasons: this.filterItems.generalFeedbackReasons.value,
        gardener_id: parseInt(this.filterItems.gardener.value) || "all",
      };

      if (this.period) {
        payload.period = this.period;
      }

      if (start_date) {
        payload.start_date = start_date;
        payload.end_date = end_date;
      }

      this.filtering = true;
      feedback
        .filter(this.type, payload)
        .then((response) => {
          let newPageData = [];
          const filteredData = response.data.data;

          Object.keys(filteredData).forEach((customer) => {
            newPageData.push(filteredData[customer]);
          });
          this.pageData.data = newPageData;
          this.pageData.total = this.pageData.data.length;
          this.hidePagination = true;
          this.showFilters = !start_date;
          this.filtering = false;
        })
        .catch((error) => {
          this.filtering = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getReasons() {
      feedback.allReasons().then((response) => {
        if (response.data.status) {
          const services = response.data.data;
          Object.keys(services).forEach((service) => {
            this.reasons[service.toLowerCase()] =
              this.type === "happy"
                ? services[service][0].good
                : services[service][0].bad;
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
