<template>
  <div>
    <el-dialog
      title="Export Feedback"
      :visible.sync="shouldShow"
      @close="closeEvent"
      width="30%"
    >
      <el-form label-position="top" :model="form" ref="form">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Select the data you'd like to export">
              <el-checkbox-group v-model="form.export_parameters">
                <el-checkbox
                  v-for="(value, key, i) in parameters"
                  :key="i"
                  :label="key"
                  :disabled="
                    key === 'order' || key === 'customer' || key === 'rating'
                  "
                  >{{ value }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Select data format">
              <el-radio-group v-model="form.format">
                <el-radio label="pdf">PDF</el-radio>
                <el-radio label="csv">CSV</el-radio>
                <el-radio label="excel">Excel</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="w-100 is-flex is-justify-end mt-2">
        <el-button type="primary" :loading="exporting" @click="exportFeedback"
          >Export</el-button
        >
      </div>
    </el-dialog>
    <download-csv
      :data="data.data || []"
      :title="data.title"
      :name="data.fileName"
    >
      <div class="csvDownloadButton">csv</div>
    </download-csv>
    <download-excel
      :data="data.data || []"
      :title="data.title"
      :name="data.fileName"
    >
      <div class="excelDownloadButton">excel</div>
    </download-excel>
  </div>
</template>

<script>
export default {
  name: "FeedbackExport",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    exportData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      exporting: false,
      formattedExportData: {},
      data: {},
      form: {
        export_parameters: ["order", "customer", "rating"],
        format: "pdf"
      },
      parameters: {
        order_date: "Order date",
        order: "Order Id",
        customer: "Customer",
        gardener: "Gardener",
        service: "Service",
        rating: "Rating",
        general_experience: "General Experience",
        general_feedback: "General Feedback",
        feedback_date: "Feedback date",
        specific_feedback: "Specific Feedback"
      }
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      }
    }
  },
  watch: {
    show() {
      this.formatExportData();
      console.log(this.exportData);
    }
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.exporting = false;
      this.$refs.form.resetFields();
    },
    exportFeedback() {
      this.exporting = true;
      if (this.exportData.length) {
        this.formatData();
      } else {
        this.$message.error("No data to export");
      }
    },
    formatExportData() {
      let orders = {};
      this.exportData.forEach(feedback => {
        feedback.forEach(item => {
          if (!orders[item.customer_order_id]) {
            orders[item.customer_order_id] = {
              customer: this.formatName(item.customer_name),
              gardener: item.gardener_name,
              service: this.formatText(item.service),
              rating: this.formatText(item.general_experience_rating),
              general_experience: item.general_experience_reasons.length
                ? item.general_experience_reasons.toString()
                : "-",
              general_feedback: item.general_feedback,
              order_date: this.dateHypened(item.delivery_date),
              feedback_date: this.dateHypened(item.created_at),
              items: []
            };
          }
          orders[item.customer_order_id].items.push({
            item: this.formatToTitleCase(item.commodity),
            item_rating: this.formatText(item.commodity_rating),
            item_reasons: item.commodity_reasons.length
              ? item.commodity_reasons.toString()
              : "None",
            item_experience: item.commodity_extra_feedback ?? "None"
          });
        });
      });
      this.formattedExportData = orders;
    },
    formatData() {
      const currentDateAndTime = this.dateAndTimeHypened(new Date());
      const title = `Feedback Data - ${currentDateAndTime}`;

      let headers = {
        no: "No"
      };
      this.form.export_parameters.forEach(key => {
        headers[key] = this.parameters[key];
      });

      let feedback_data = [];
      Object.keys(this.formattedExportData).forEach((key, index) => {
        const order = this.formattedExportData[key];

        const no = index + 1;
        const order_date = order.order_date;
        const customer = order.customer;
        const gardener = order.gardener;
        const service = order.service;
        const rating = order.rating;
        const general_experience = order.general_experience;
        const general_feedback = order.general_feedback;
        const feedback_date = order.feedback_date;
        let items = "";
        order.items.forEach(item => {
          items += `${items}\n${item.item} - [${item.item_rating}] - [${item.item_reasons}] - [${item.item_experience}]`;
        });

        let feedback = {
          no,
          order_date,
          order: key,
          customer,
          gardener,
          service,
          rating: rating ?? "None",
          general_experience: general_experience ?? "None",
          general_feedback: general_feedback ?? "None",
          feedback_date,
          specific_feedback: items
        };

        let export_feedback = {};
        Object.keys(headers).forEach(key => {
          export_feedback[key] = feedback[key];
        });
        feedback_data.push(export_feedback);
      });

      this.data = {
        title,
        headers,
        data: feedback_data,
        fileName: `Feedback Data`
      };
      this.download();
    },
    download() {
      setTimeout(() => {
        this.downloadData();
        this.closeEvent();
      }, 1000);
    },
    downloadData() {
      switch (this.form.format) {
        case "pdf":
          this.downloadPDF(this.data);
          break;
        case "csv":
          this.downloadCSV(this.data);
          break;
        case "excel":
          this.downloadExcel(this.data);
          break;
        default:
          break;
      }
    },
    downloadPDF() {
      this.generatePDF(this.data);
    },
    downloadCSV() {
      setTimeout(() => {
        document.querySelector(".csvDownloadButton").click();
      }, 1000);
    },
    downloadExcel() {
      setTimeout(() => {
        document.querySelector(".excelDownloadButton").click();
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.csvDownloadButton,
.excelDownloadButton {
  position: absolute;
  opacity: 0;
}
</style>
